<template>
  <div v-for="(row, index) in Tests" :key="row" class="test-placeholder">
    <div class="test-result" v-on:click="GotoInfo(row.id, index)">
      <div class="test-block">
        <div class="test-id">#{{ getIndex(index) }}</div>
        <div class="test-animalid">
          <small>Sample ID</small>
          {{ row.cow_id }}
        </div>
      </div>
      <div class="test-block">
        <div class="test-status">
          <span
            v-if="row.status == 'green'"
            class="icon icon-heart"
            style="color: #04a394"
          ></span>
          <span
            v-else-if="row.status == 'red'"
            class="icon icon-warning"
            style="color: #d50014"
          ></span>
          <span
            v-else-if="row.status == 'failed'"
            class="icon icon-warning"
            style="color: grey"
          ></span>
          <span v-else class="icon icon-heart"></span>
        </div>
        <div class="test-date">{{ TimeParse(row.created_at) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  name: "RecentTests",
  data() {
    return {
      Tests: [],
      TestsAvailable: 0,
    };
  },
  methods: {
    GetTests() {
      this.store
        .dispatch("Tests/GetLatestTests")
        .then((data) => {
          if (data.data.length > 0) {
            this.TestsAvailable = this.store.state["User"].info.available_test;
            this.Tests = data.data;
            this.TotalTests = data.total;
          } else {
            this.Tests = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.Tests = [];
        });
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    getIndex(index) {
      return this.TotalTests - index;
    },
    GotoInfo(id, index) {
      index = this.getIndex(index);
      this.$router.push({
        name: "Result_View",
        params: { id: id, index: index },
      });
    },
  },
  mounted() {
    this.GetTests();
  },
};
</script>

<style scoped>
.test-placeholder {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
.test-result {
  margin: 0 auto;
  height: 80px;
  width: 80%;
  background: #e4e7ee;
  border-radius: 3px;
  border: 1px solid #dddddd;
}
.test-block {
  width: 50%;
  height: 100%;
  display: inline-block;
}
.test-block:nth-child(2) {
  width: 50%;
  height: 100%;
  float: right;
}
.test-id {
  font-size: 20px;
  font-weight: bolder;
  height: 35px;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  line-height: 35px;
  display: block;
  float: left;
}
.test-animalid small {
  position: absolute;
  line-height: 25px;
  font-size: 10px;
  display: block;
  color: inherit;
}
.test-animalid {
  font-size: 17px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  line-height: 60px;
  display: block;
  float: left;
  overflow: hidden;
  box-sizing: border-box;
}
.test-status {
  font-size: 20px;
  display: block;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  float: right;
  line-height: 45px;
  margin: 10px 10px 0px 0px;
  border-radius: 50px;
}
.test-date {
  font-size: 17px;
  height: 40px;
  width: 100%;
  padding-right: 10px;
  line-height: 35px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  text-align: right;
}
.test-result:hover {
  border: 1px solid #35bba4;
}
</style>
